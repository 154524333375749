import React from 'react';


import './styles.scss';
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const Captain = () => {
  return (
    <>
      <section className="become-captain">
        <div className="content">
          <h2 className="text-center title">Γίνετε καπετάνιος</h2>
          <p className="text-center paragraph">Θέλετε να γίνετε καπετάνιος, επικοινωνήστε μαζί μας για περισσότερες πληροφορίες.</p>
          <div className="contact-btn"><AnchorLink className="btn" to="/#contact">Επικοινωνηστε μαζι μας</AnchorLink></div>
        </div>
        <div className="ocean">
          <div className="wave"></div>
          <div className="wave"></div>
        </div>
      </section>
    </>
  )
}

export default Captain;