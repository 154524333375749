import React, { useEffect, Component } from "react";
import { graphql, useStaticQuery } from 'gatsby'

import "./styles.scss"
import Captain from "../Components/Captain/Captain"
import Footer from "../Components/Footer/Footer"


import Layout from "../Components/layout"
// import SEO from "../components/seo"

const BecomeCaptain = (props) => {
  // console.log(props);
  return(
    <div>
      <Layout>
        <Captain/>
        <Footer/>
      </Layout>
    </div>
  )
}

export default BecomeCaptain